<template>
  <CRow class="justify-content-center m-0 mt-3">
      <CCol sm="12" lg="12">
          <CCol sm="12" class="mb-2">
            <vue-editor
              :placeholder="$t('label.description')"
              v-model="$v.DescriptionOfGoods.DescriptionOfGoods.$model"
              :class="!$v.DescriptionOfGoods.DescriptionOfGoods.$dirty ? '' : 
                ($v.DescriptionOfGoods.DescriptionOfGoods.$error ? 'select-description--error' : 'select-description--correct')"
            >
            </vue-editor>
            <div v-if="$v.DescriptionOfGoods.DescriptionOfGoods.$error" style="display: block;" class="invalid-feedback">
              {{ errorMessage($v.DescriptionOfGoods.DescriptionOfGoods) }}
            </div>
          </CCol>
      </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import BlContainerValidations from "@/_validations/planificacion-estiba/blContainerValidations";
import { VueEditor } from "vue2-editor";
  
function data() {
  return {
  };
}

//computed
  
export default {
  name: 'description-of-goods-tab',
  components: {
    VueEditor
  },
  data,
  props: {
    DescriptionOfGoods: {
      type: Object,
      required: true,
      default: () => {},
    },
    showModal: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  validations() {
    return BlContainerValidations();
  },
  methods: {

  },
  computed:{

  },
  watch: {
    showModal: function (Newval) {
      if (Newval) {
        this.$v.DescriptionOfGoods.$touch();
      }else{
        this.$v.$reset();
      }
    },
  }
};
</script>