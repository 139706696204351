var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading-overlay',{attrs:{"active":_vm.Loading,"is-full-page":true,"loader":"bars"}}),_c('CRow',{staticClass:"mx-0 mb-2"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-start",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.$t('label.return'),
            placement: 'top',
          }),expression:"{\n            content: $t('label.return'),\n            placement: 'top',\n          }"}],staticClass:"mr-2",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.Reset()}}},[_c('CIcon',{attrs:{"name":"cil-chevron-left-alt"}})],1),_c('h6',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(`${_vm.$t('label.BlContainerList')}:`)+" "),_c('strong',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(`${_vm.Bl.NroBl ?? ''}`)+" ")])])],1)],1),_c('CRow',{staticClass:"m-0"},[_c('CCol',{attrs:{"col":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('CButton',{attrs:{"color":"excel","size":"sm"},on:{"click":function($event){return _vm.onBtnExport(true)}}},[_c('CIcon',{attrs:{"name":"cil-file-excel"}}),_vm._v("  XSLX ")],1)],1)]),_c('CCol',{attrs:{"col":"12","lg":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"sorter":"","column-filter":"","items":_vm.formatedItems,"fields":_vm.fields,"items-per-page":5,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"pagination":""},on:{"filtered-items-change":function($event){_vm.filteredList=$event}},scopedSlots:_vm._u([{key:"Option",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: `${_vm.$t('label.edit')} ${_vm.$t('label.container')}`,
                  placement: 'top-start',
                }),expression:"{\n                  content: `${$t('label.edit')} ${$t('label.container')}`,\n                  placement: 'top-start',\n                }"}],attrs:{"color":"edit","size":"sm"},on:{"click":function($event){(_vm.VisitCargoId=item.VisitCargoId,_vm.ActivateContainerModal=true)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1),_c('ContainerModal',{attrs:{"modal":_vm.ActivateContainerModal,"VisitCargoId":_vm.VisitCargoId,"BlClientTpId":_vm.Bl.ClientTpId},on:{"update:modal":function($event){_vm.ActivateContainerModal=$event},"Close":function($event){(_vm.ActivateContainerModal=false, _vm.VisitCargoId='')},"Update":_vm.UpdateList}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }