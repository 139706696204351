<template>
  <CRow class="mx-0 mt-3">
    <CCol sm="12" class="mb-3">
      <label class="col-sm-12 col-lg-2 px-0 m-0 text-right">
        <b>ISO</b>
      </label>
    </CCol>
    <CCol sm="12" lg="6">
      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-5 mb-0 text-right">{{$t('label.containerType')}}</label>
        <div class="input-group col-sm-12 col-lg-7">
          <CInput
            size="sm"
            required 
            disabled
            class="mb-0 pl-0 col-sm-5 pr-1"
            :value.async="IsoData.MetadataDesc"
          />
          <v-select
            id="v-select-small"
            :class="`mb-0 px-0 col-sm-7 ${$v.IsoSeals.MetadataId.$error ? 'select-client--error' : 'select-client--correct'}`"
            :placeholder="$t('label.select')"
            :options="MetadataOptions"
            :reduce="option => option.value" 
            v-model="IsoSeals.MetadataId"
            style="height: 29px;"
          >
            <template #no-options="{}">
              {{$t('label.noResultsFound')}}
            </template>
            <template slot="option" slot-scope="option" >
              <span>
                &nbsp;
              </span>
              {{ option.label }}
            </template>
          </v-select>
          <div class="text-invalid-feedback" v-if="$v.IsoSeals.MetadataId.$error">
            {{ errorMessage($v.IsoSeals.MetadataId) }}
          </div>
        </div>
      </div>

      <CInput
        v-uppercase
        size="sm"
        addLabelClasses="text-right"
        :label="$t('label.type')"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        :is-valid="true"
        :disabled="true"
        :value.async="IsoData.TpCargoName"
      >
      </CInput>

      <CInput
        v-uppercase
        size="sm"
        addLabelClasses="text-right"
        :label="$t('label.typecargocode')"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        :is-valid="true"
        :disabled="true"
        :value.async="IsoData.TpCargoCode"
      >
      </CInput>

      <CInput
        v-uppercase
        size="sm"
        class="mb-3"
        addLabelClasses="text-right"
        :label="$t('label.SizeFt')"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        :is-valid="true"
        :disabled="true"
        :value.async="IsoData.size"
      >
      </CInput>

      <CInput
        v-uppercase
        size="sm"
        addLabelClasses="text-right"
        :label="$t('label.description')"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        :is-valid="true"
        :value.async="IsoData.TpCargoDetailName"
        disabled
      />
    </CCol>
    <CCol sm="12" lg="6">
      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-2 mb-0 text-right required">{{$t('label.largo')}}</label>
        <div class="input-group input-group-sm col-sm-12 col-lg-7">
          <money
            size="sm"
            v-bind="measure"
            :class="`col-sm-6 ${$v.IsoSeals.Length.$error ? 'form-control is-invalid' : 'form-control is-valid'}`"
            :value.async="MetadataLength"
            maxlength= "11"
            disabled
          />
          <CSelect
            size="sm"
            class="mb-0 pl-1 pr-0 col-sm-6"
            :options="LengthUnitMeasureOptions"
            :placeholder="$t('label.select')"
            :is-valid="hasError($v.IsoSeals.UnitMeasureLength)"
            v-model="$v.IsoSeals.UnitMeasureLength.$model"
          />
          <div class="text-invalid-feedback" v-if="$v.IsoSeals.Length.$error||$v.IsoSeals.UnitMeasureLength.$error">
            <div v-if="$v.IsoSeals.UnitMeasureLength.$error">
              {{ errorMessage($v.IsoSeals.UnitMeasureLength) }}
            </div>
            <div v-else>
              {{ !$v.IsoSeals.Length.maxValue ? `${$t('validation.maxValue')}: 99.999,99` : errorMessage($v.IsoSeals.Length) }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-2 mb-0 text-right required">{{$t('label.height')}}</label>
        <div class="input-group input-group-sm col-sm-12 col-lg-7">
          <money
            size="sm"
            v-bind="measure"
            :class="`col-sm-6 ${$v.IsoSeals.Height.$error ? 'form-control is-invalid' : 'form-control is-valid'}`"
            :value.async="MetadataHeigth"
            maxlength= "11"
            disabled
          />
          <CSelect
            size="sm"
            class="mb-0 pl-1 pr-0 col-sm-6"
            :options="HeightUnitMeasureOptions"
            :placeholder="$t('label.select')"
            :is-valid="hasError($v.IsoSeals.UnitMeasureHeight)"
            v-model="$v.IsoSeals.UnitMeasureHeight.$model"
          />
          <div class="text-invalid-feedback" v-if="$v.IsoSeals.UnitMeasureHeight.$error||$v.IsoSeals.Height.$error">
            <div v-if="$v.IsoSeals.UnitMeasureHeight.$error">
              {{ errorMessage($v.IsoSeals.UnitMeasureHeight) }}
            </div>
            <div v-else>
              {{ !$v.IsoSeals.Height.maxValue ? `${$t('validation.maxValue')}: 99.999,99` : errorMessage($v.IsoSeals.Height) }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-2 mb-0 text-right required">{{$t('label.weight')}}</label>
        <div class="input-group input-group-sm col-sm-12 col-lg-7">
          <money
            size="sm"
            v-bind="measure"
            :class="`col-sm-6 ${$v.IsoSeals.Weight.$error ? 'form-control is-invalid' : 'form-control is-valid'}`"
            v-model="$v.IsoSeals.Weight.$model"
            maxlength= "11"
          />
          <CSelect
            size="sm"
            class="mb-0 pl-1 pr-0 col-sm-6"
            :options="WeightUnitMeasureOptions"
            :placeholder="$t('label.select')"
            v-model="$v.IsoSeals.UnitMeasureWeight.$model"
            :is-valid="hasError($v.IsoSeals.UnitMeasureWeight)"
            @change="WeightConversion($event.target.value)"
          />
          <div class="text-invalid-feedback" v-if="$v.IsoSeals.Weight.$error||$v.IsoSeals.UnitMeasureWeight.$error">
            <div v-if="$v.IsoSeals.UnitMeasureWeight.$error">
              {{ errorMessage($v.IsoSeals.UnitMeasureWeight) }}
            </div>
            <div v-else>
              {{ !$v.IsoSeals.Weight.required ? errorMessage($v.IsoSeals.Weight) : `${$t('label.container_data.container_weight')} ${Validate.LimitWeight}` }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-2 mb-0 text-right">{{$t('label.vgm')}}</label>
        <div class="input-group input-group-sm col-sm-12 col-lg-7">
          <money
            size="sm"
            v-bind="measure"
            :class="`col-sm-6 ${$v.IsoSeals.Vgm.$error ? 'form-control is-invalid' : 'form-control is-valid'}`"
            v-model="$v.IsoSeals.Vgm.$model"
            maxlength= "11"
          />
          <CSelect
            size="sm"
            class="mb-0 pl-1 pr-0 col-sm-6"
            :options="VgmUnitMeasureOptions"
            :placeholder="$t('label.select')"
            :is-valid="hasError($v.IsoSeals.UnitMeasureVgm)"
            v-model="$v.IsoSeals.UnitMeasureVgm.$model"
            @change="VgmConversion($event.target.value)"
          />
          <div class="text-invalid-feedback" v-if="$v.IsoSeals.Vgm.$error||$v.IsoSeals.UnitMeasureVgm.$error">
            <div v-if="$v.IsoSeals.UnitMeasureVgm.$error">
              {{ errorMessage($v.IsoSeals.UnitMeasureVgm) }}
            </div>
            <div v-else>
              {{ `${$t('label.container_data.container_weight')} ${Validate.LimitVgm}` }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-form-label-sm col-sm-12 col-lg-2 mb-0 text-right">{{$t('label.volume')}}</label>
        <div class="input-group input-group-sm col-sm-12 col-lg-7">
          <money
            size="sm"
            v-bind="measure"
            :class="`col-sm-6 ${$v.IsoSeals.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid'}`"
            v-model="$v.IsoSeals.Volumen.$model"
            maxlength= "11"
          />
          <CSelect
            size="sm"
            class="mb-0 pl-1 pr-0 col-sm-6"
            :options="VolumenUnitMeasureOptions"
            :placeholder="$t('label.select')"
            :is-valid="hasError($v.IsoSeals.UnitMeasureVolumen)"
            v-model="$v.IsoSeals.UnitMeasureVolumen.$model"
            @change="VolumenConversion($event.target.value)"
          />
          <div class="text-invalid-feedback" v-if="$v.IsoSeals.UnitMeasureVolumen.$error">
            {{ errorMessage($v.IsoSeals.UnitMeasureVolumen) }}
          </div>
        </div>
      </div>
    </CCol>
    <CCol sm="12" class="mb-3 mt-2">
      <label class="col-sm-12 col-lg-2 px-0 m-0 text-right">
        <b>{{$t('label.Seal')}}</b>
      </label>
    </CCol>
    <CCol sm="12" lg="6">
      <CInput
        v-uppercase
        size="sm"
        addLabelClasses="text-right"
        :label="`${$t('label.Seal')} 1`"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        :is-valid="hasError($v.IsoSeals.Seal1)"
        v-model="$v.IsoSeals.Seal1.$model"
      />
    </CCol>
    <CCol sm="12" lg="6">
      <CInput
        v-uppercase
        size="sm"
        addLabelClasses="text-right"
        :label="`${$t('label.Seal')} 2`"
        :horizontal="{label:'col-sm-12 col-lg-2', input:'col-sm-12 col-lg-7'}"
        :is-valid="hasError($v.IsoSeals.Seal1)"
        v-model="$v.IsoSeals.Seal2.$model"
      />
    </CCol>
    <CCol sm="12" lg="6">
      <CInput
        v-uppercase
        size="sm"
        addLabelClasses="text-right"
        :label="`${$t('label.Seal')} 3`"
        :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
        :is-valid="hasError($v.IsoSeals.Seal1)"
        v-model="$v.IsoSeals.Seal3.$model"
      />
    </CCol>
    <CCol sm="12" lg="6">
      <CInput
        v-uppercase
        size="sm"
        addLabelClasses="text-right"
        :label="`${$t('label.Seal')} 4`"
        :horizontal="{label:'col-sm-12 col-lg-2', input:'col-sm-12 col-lg-7'}"
        :is-valid="hasError($v.IsoSeals.Seal1)"
        v-model="$v.IsoSeals.Seal4.$model"
      />
    </CCol>
    <CCol sm="12" class="text-invalid-feedback text-center"
      v-if="!Validate.ValidateStatusEmpty && !IsoSeals.Seal1 && !IsoSeals.Seal2 && !IsoSeals.Seal3 && !IsoSeals.Seal4"
    >
      <label>{{ $t('label.AtLeastSealRequired') }}</label>
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import BlContainerValidations from "@/_validations/planificacion-estiba/blContainerValidations";
import UpperCase from '@/_validations/uppercase-directive';
import { Money } from "v-money";
import { NumberFormater } from "@/_helpers/funciones";
  
function data() {
  return {
    measure: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false,
    },
  };
}

//methods
async function Conversions(newValue, OldValue, Value){
  try{
    this.$emit('Loading', true);
    let Conversion = NumberFormater.setNum(Value);
    await this.$http.ejecutar('GET', 'UnitMeasureConversion', { UnitMeasureToId: newValue, UnitMeasureFromId: OldValue, Value: Conversion })
      .then(response => {
        let Result = response.data.data && response.data.data[0] ? response.data.data[0].Value : 0;
        return Result;
    }).catch(err => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
      return 0;
    })
  } catch (error) {
    this.$emit('Loading', false);
    return 0;
  }
}

function WeightConversion(Id) {
  this.IsoSeals.UnitMeasureWeight = Id ?? '';
  if (this.IsoSeals.UnitMeasureWeight) {
    if (this.IsoSeals.PreviousWeightUnitMeasure) {
      this.IsoSeals.Weight = this.Conversions(this.IsoSeals.UnitMeasureWeight, this.IsoSeals.PreviousWeightUnitMeasure, this.IsoSeals.Weight);
    }
    this.IsoSeals.PreviousWeightUnitMeasure = this.IsoSeals.UnitMeasureWeight;
  }else{
    this.IsoSeals.Weight = 0;
    this.IsoSeals.PreviousWeightUnitMeasure = '';
  }
}

function VgmConversion(Id) {
  this.IsoSeals.UnitMeasureVgm = Id ?? '';
  if (this.IsoSeals.UnitMeasureVgm) {
    if (this.IsoSeals.PreviousVgmUnitMeasure) {
      this.IsoSeals.Vgm = this.Conversions(this.IsoSeals.UnitMeasureVgm, this.IsoSeals.PreviousVgmUnitMeasure, this.IsoSeals.Vgm);
    }
    this.IsoSeals.PreviousVgmUnitMeasure = this.IsoSeals.UnitMeasureVgm;
  }else{
    this.IsoSeals.Vgm = 0;
    this.IsoSeals.PreviousVgmUnitMeasure = '';
  }
}

function VolumenConversion(Id) {
  this.IsoSeals.UnitMeasureVolumen = Id ?? '';
  if (this.IsoSeals.UnitMeasureVolumen) {
    if (this.IsoSeals.PreviousVolumenUnitMeasure) {
      this.IsoSeals.Volumen = this.Conversions(this.IsoSeals.UnitMeasureVolumen, this.IsoSeals.PreviousVolumenUnitMeasure, this.IsoSeals.Volumen);
    }
    this.IsoSeals.PreviousVolumenUnitMeasure = this.IsoSeals.UnitMeasureVolumen;
  }else{
    this.IsoSeals.Volumen = 0;
    this.IsoSeals.PreviousVolumenUnitMeasure = '';
  }
}

//computed
function MetadataOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.IsoList.map(item => {
    chart.push({
      value: item.MetadataId, 
      label: item.MetadataDesc,
    })    
  })
  return chart;
}

function LengthUnitMeasureOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.UnitMeasureLongitudeList.map(item => {
    chart.push({
      value: item.UnitMeasureId, 
      label: item.UnitMeasureAbbrev,
    })    
  })
  return chart;
}

function HeightUnitMeasureOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.UnitMeasureLongitudeList.map(item => {
    chart.push({
      value: item.UnitMeasureId, 
      label: item.UnitMeasureAbbrev,
    })    
  })
  return chart;
}

function WeightUnitMeasureOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.UnitMeasureWeigthList.map(item => {
    chart.push({
      value: item.UnitMeasureId, 
      label: item.UnitMeasureAbbrev,
    })    
  })
  return chart;
}

function VgmUnitMeasureOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.UnitMeasureWeigthList.map(item => {
    chart.push({
      value: item.UnitMeasureId, 
      label: item.UnitMeasureAbbrev,
    })    
  })
  return chart;
}

function VolumenUnitMeasureOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.UnitMeasureVolumenList.map(item => {
    chart.push({
      value: item.UnitMeasureId, 
      label: item.UnitMeasureAbbrev,
    })    
  })
  return chart;
}

function MetadataLength() {
  let Data = this.IsoData?.LenghtJson?.find((item) => this.IsoSeals.UnitMeasureLength === item.UnitMeasureId) ?? {};
  this.IsoSeals.Length = Number.parseFloat(Data?.Value) ?? 0;
  return Data?.Value ?? 0;
}

function MetadataHeigth() {
  let Data = this.IsoData?.HeigthJson?.find((item) => this.IsoSeals.UnitMeasureHeight === item.UnitMeasureId) ?? {};
  this.IsoSeals.Height = Number.parseFloat(Data?.Value) ?? 0;
  return Data?.Value ?? 0;
}
  
export default {
  name: 'iso-seals-tab',
  components: {
    Money,
  },
  data,
  props: {
    IsoSeals: {
      type: Object,
      required: true,
      default: () => {},
    },
    IsoData: {
      type: Object,
      default: () => {},
    },
    IsoList: {
      type: Array,
      default: () => [],
    },
    UnitMeasureLongitudeList: {
      type: Array,
      default: () => [],
    },
    UnitMeasureWeigthList: {
      type: Array,
      default: () => [],
    },
    UnitMeasureVolumenList: {
      type: Array,
      default: () => [],
    },
    Validate: {
      type: Object,
      default: () => {},
    },
    showModal: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return BlContainerValidations(this.Validate);
  },
  methods: {
    Conversions,
    WeightConversion,
    VgmConversion,
    VolumenConversion,
  },
  computed:{
    MetadataOptions,
    LengthUnitMeasureOptions,
    HeightUnitMeasureOptions,
    WeightUnitMeasureOptions,
    VgmUnitMeasureOptions,
    VolumenUnitMeasureOptions,
    MetadataLength,
    MetadataHeigth,
  },
  watch: {
    showModal: function (Newval) {
      if (Newval) {
        this.$v.IsoSeals.$touch();
      }else{
        this.$v.$reset();
      }
    },
  }
};
</script>