<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="xl"
      @update:show="Close()"
    >
      <CRow>
        <CCol sm="12">
          <CustomTabs :active-tab="TabIndex" @update:activeTab="handleTab" class="text-table">
            <CustomTab :class="$v.GeneralInfo.$error ? 'tab-error' : ''">
              <template #title>
                <span>{{$t('label.generalInfo')}}</span>
              </template>
              <GeneralInfoTab
                :GeneralInfo="$v.GeneralInfo.$model"
                :showModal="show"
                :StatusList="StatusList"
                :ClassList="ClassList"
                :ShippingLineList="ShippingLineList"
                :PortActivityList="PortActivityList"
                :ServicePortMasterList="ServicePortMasterList"
                :PortList="PortList"
                :YardList="YardList"
                :Validate="Validate"
              />
            </CustomTab>
            <CustomTab :class="$v.Commodity.$error ? 'tab-error' : ''">
              <template #title>
                <span>{{`${$t('label.commodity')}`}}</span>
              </template>
              <CommodityTab
                :Commodity="$v.Commodity.$model"
                :ConsigneeList="ConsigneeList"
                :HeadingList="HeadingList"
                :Validate="Validate"
                :showModal="show"
                @Update="getService"
              />
            </CustomTab>
            <CustomTab :class="$v.IsoSeals.$error ? 'tab-error' : ''">
              <template #title>
                <span>{{`${$t('ISO')} - ${$t('label.Seals')}`}}</span>
              </template>
              <IsoSealsTab
                :IsoSeals="$v.IsoSeals.$model"
                :IsoData="IsoData"
                :IsoList="IsoList"
                :UnitMeasureLongitudeList="UnitMeasureLongitudeList"
                :UnitMeasureWeigthList="UnitMeasureWeigthList"
                :UnitMeasureVolumenList="UnitMeasureVolumenList"
                :Validate="Validate"
                :showModal="show"
                @Loading="Loading=$event"
              />
            </CustomTab>
            <CustomTab :class="$v.DangerousGood.$error ? 'tab-error' : ''">
              <template #title>
                <span>{{$t('label.DangerousGood')}}</span>
              </template>
              <DangerousGoodTab
                :DangerousGood="$v.DangerousGood.$model"
                :ImdgClassCodeList="ImdgClassCodeList"
                :PackagingGroupList="PackagingGroupList"
                :showModal="show"
              />
            </CustomTab>
            <CustomTab :class="$v.DangerousGood.$error ? 'tab-error' : ''">
              <template #title>
                <span>{{$t('label.DescriptionOfGoods')}}</span>
              </template>
              <DescriptionOfGoodsTab
                :DescriptionOfGoods="$v.DescriptionOfGoods.$model"
                :showModal="show"
              />
            </CustomTab>
            <CustomTab v-if="IsoSeals.FgApplyRefrigeration" :class="$v.ReeferEquipment.$error ? 'tab-error' : ''">
              <template #title>
                <span>{{$t('label.ReeferEquipmet')}}</span>
              </template>
              <ReeferEquipmentTab
                :ReeferEquipment="$v.ReeferEquipment.$model"
                :UnitMeasureTemperatureList="UnitMeasureTemperatureList"
                :Validate="Validate"
                :showModal="show"
              />
            </CustomTab>
            <CustomTab v-if="IsoSeals.FgApplyOversize" :class="$v.OversizedEquipment.$error ? 'tab-error' : ''">
              <template #title>
                <span>{{$t('label.OversizedEquipment')}}</span>
              </template>
              <OversizedEquipmentTab
                :OversizedEquipment="$v.OversizedEquipment.$model"
                :UnitMeasureDimList="UnitMeasureDimList"
                :Validate="Validate"
                :showModal="show"
              />
            </CustomTab>
          </CustomTabs>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="Submit"
        >
            <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
        </CButton>
        <CButton color="wipe"  @click="Close">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { NumberFormater } from "@/_helpers/funciones";
import ModalMixin from '@/_mixins/modal';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import BlContainerValidations from "@/_validations/planificacion-estiba/blContainerValidations";
import GeneralInfoTab from './general-info-tab';
import CommodityTab from './commodity-tab';
import IsoSealsTab from './iso-seals-tab';
import DangerousGoodTab from './dangerous-good-tab';
import DescriptionOfGoodsTab from './description-of-goods-tab';
import ReeferEquipmentTab from './reefer-equipment-tab';
import OversizedEquipmentTab from './oversized-equipment-tab';

function data() {
  return {
    showModal: false,
    show: false,
    isSubmit: false,
    Loading: false,
    MountedEdit: true,
    TabIndex: 0,
    Title: '',
    GeneralInfo: {
      ContainerCode: '',
      TpCargoStatusId: '',
      TpCargoConditionId: '',
      TpCargoClassId: '',
      ShippingLineId: '',
      PortActivityId: '',
      ShipperOwn: '',
      FgShipperOwn: false,
      FgDirectDischarge: false,
      LoadPortId: '',
      DischargePortId: '',
      DeliveryPortId: '',
      CargoOriginPortId: '',
      YardId: '',
      Procedence: '',
      BookingNro: '',
      Status: 1,
    },
    StatusList: [],
    ClassList: [],
    ShippingLineList: [],
    PortActivityList: [],
    ServicePortMasterList: [],
    PortList: [],
    YardList: [],
    Commodity: {
      BlNro: '',
      Dua: '',
      ConsigneeId: '',
      HeadingId: '',
      CommodityId: '',
    },
    ConsigneeList: [],
    HeadingList: [],
    IsoSeals: {
      FgApplyRefrigeration: false,
      FgApplyOversize: false,
      MetadataId: '',
      Length: 0,
      UnitMeasureLength: '',
      Height: 0,
      UnitMeasureHeight: '',
      Weight: 0,
      UnitMeasureWeight: '',
      PreviousWeightUnitMeasure: '',
      Vgm: 0,
      UnitMeasureVgm: '',
      PreviousVgmUnitMeasure: '',
      Volumen: 0,
      UnitMeasureVolumen: '',
      PreviouVolumenUnitMeasure: '',
      Seal1: '',
      Seal2: '',
      Seal3: '',
      Seal4: '',
    },
    IsoList: [],
    UnitMeasureLongitudeList: [],
    UnitMeasureWeigthList: [],
    UnitMeasureVolumenList: [],
    DangerousGood: {
      ImdgId: '',
      ImdgClassId: '',
      UnNumber: '',
      PackagingGroupId: '',
      DangerousGood: '',
    },
    ImdgClassCodeList: [],
    PackagingGroupList: [],
    DescriptionOfGoods: {
      DescriptionOfGoods: '',
    },
    ReeferEquipment: {
      ReeferSetting: '',
      UnitMeasureRf: '',
      PreviousRfUnitMeasure: '',
      MinimumRangeRf: 0,
      MaximumRangeRf: 0,
    },
    UnitMeasureTemperatureList: [],
    OversizedEquipment: {
      UnitMeasureDim: '',
      PreviousDimUnitMeasure: '',
      DimFront: 0,
      DimBack: 0,
      DimLeft: 0,
      DimRight: 0,
      DimTop: 0,
    },
    UnitMeasureDimList: [],
  }
}

//methods
async function getService(ContainerDataOnly) {
  this.Loading = true;
  let requests = [];
  if (ContainerDataOnly) {
    requests = [
      this.$http.get("VisitGeneralCargoContainerData", { VisitCargoId: this.VisitCargoId ?? '' }),
    ];
  } else {
    requests = [
      this.$http.get("VisitGeneralCargoContainerData", { VisitCargoId: this.VisitCargoId ?? '' }),
      this.$http.get('TpCargoDetail-by-codiso', { CodIso: ''}),
      this.$http.get('YardContainer-list', { CompanyBranchId: this.branch.CompanyBranchId}),
    ];
  }
  await Promise.all(requests)
    .then(async(responses) => {
      let List = {};
      if (ContainerDataOnly) {
        List = responses[0].data.data && responses[0].data.data[0] ? responses[0].data.data[0] : {};
      } else {
        List = responses[0].data.data && responses[0].data.data[0] ? responses[0].data.data[0] : {};
        this.IsoList = responses[1].data.data && responses[1].data.data.Length != 0 ? responses[1].data.data : [];
        this.YardList = responses[2].data.data && responses[2].data.data.Length != 0 ? responses[2].data.data : [];
      }
      await this.getData(List.CargoJson[0], List);
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.Loading = false;
    });
}

function Submit() {
  try {
    this.Loading = true;
    this.isSubmit = true;
    this.$v.$touch();
    if (this.$v.validationGroup.$error || !this.$v.Commodity.ConsigneeId.required || !this.$v.GeneralInfo.ContainerCode.required || !this.$v.GeneralInfo.ContainerCode.minLength || !this.$v.GeneralInfo.ContainerCode.maxLength){
      throw this.$t('label.errorsPleaseCheck');
    }

    let VisitCargoJson = [{
      VisitCargoId: this.VisitCargoId ?? '',
      ContainerCode: this.GeneralInfo.ContainerCode,
      MetadataId: this.IsoSeals.MetadataId,
      TpCargoClassId: this.GeneralInfo.TpCargoClassId,
      TpCargoStatusId: this.GeneralInfo.TpCargoStatusId,
      TpCargoConditionId: this.GeneralInfo.TpCargoConditionId,
      PortActivityId: this.GeneralInfo.PortActivityId,
      LoadPortId: this.GeneralInfo.LoadPortId,
      DischargePortId: this.GeneralInfo.DischargePortId,
      DeliveryPortId: this.GeneralInfo.DeliveryPortId,
      OriginalPortLoadingId: this.GeneralInfo.CargoOriginPortId,
      ShippingLineId: this.GeneralInfo.ShippingLineId,
      HeadingId: this.Commodity.HeadingId,
      CommodityId: this.Commodity.CommodityId,
      Procedence: this.GeneralInfo.Procedence,
      DescriptionOfGoods: this.DescriptionOfGoods.DescriptionOfGoods,
      Lenght: NumberFormater.setNum(this.IsoSeals.Length),
      UnitMeasureLenght: this.IsoSeals.UnitMeasureLength,
      Height: NumberFormater.setNum(this.IsoSeals.Height),
      UnitMeasureHeigth: this.IsoSeals.UnitMeasureHeight,
      Weigth: NumberFormater.setNum(this.IsoSeals.Weight),
      UnitMeasureWeigth: this.IsoSeals.UnitMeasureWeight,
      Vgm: NumberFormater.setNum(this.IsoSeals.Vgm),
      UnitMeasureVgm: this.IsoSeals.UnitMeasureVgm,
      Volumen: NumberFormater.setNum(this.IsoSeals.Volumen),
      UnitMeasureVolumen: this.IsoSeals.UnitMeasureVolumen,
      UnitMeasureRfId: this.ReeferEquipment.UnitMeasureRf,
      ReeferSetting: this.ReeferEquipment.ReeferSetting,
      MinimumRangeRf: NumberFormater.setNum(this.ReeferEquipment.MinimumRangeRf),
      MaximumRangeRf: NumberFormater.setNum(this.ReeferEquipment.MaximumRangeRf),
      DimFront: NumberFormater.setNum(this.OversizedEquipment.DimFront),
      DimBack: NumberFormater.setNum(this.OversizedEquipment.DimBack),
      DimLeft: NumberFormater.setNum(this.OversizedEquipment.DimLeft),
      DimRight: NumberFormater.setNum(this.OversizedEquipment.DimRight),
      DimTop: NumberFormater.setNum(this.OversizedEquipment.DimTop),
      ImdgClassId: this.DangerousGood.ImdgClassId,
      ImdgId: this.DangerousGood.ImdgId,
      UnNumberId: this.DangerousGood.UnNumber,
      PackagingGroupId: this.DangerousGood.PackagingGroupId,
      DescriptionOfGoodsDGS: this.DangerousGood.DangerousGood,
      BookingNumber: this.GeneralInfo.BookingNro,
      Seal1: this.IsoSeals.Seal1,
      Seal2: this.IsoSeals.Seal2,
      Seal3: this.IsoSeals.Seal3,
      Seal4: this.IsoSeals.Seal4,
      ConsigneeId: this.Commodity.ConsigneeId,
      ShipperOwn: this.FgShipperOwn ? this.GeneralInfo.ShipperOwn : '',
      BlNo: this.Commodity.BlNro,
      Dua: this.Commodity.Dua,
      FgDirect: this.GeneralInfo.FgDirectDischarge ? 1 : 0,
      YardId: this.GeneralInfo.YardId,
      Status: this.GeneralInfo.Status,
    }]

    this.$http.put('VisitGeneralCargo-update', VisitCargoJson, { root: 'VisitCargoJson' })
      .then(async (response) => {
        this.notifySuccess({text: response.data.data[0].Response});
        await this.$emit('Close');
        await this.Reset();
        await this.$emit("Update");
        this.isSubmit = false;
        this.Loading = false;
      }).catch((err) => {
        this.isSubmit = false;
        this.Loading = false;
        this.notifyError({text: err});
      })
  } catch (error) {
    this.isSubmit = false;
    this.Loading = false;
    this.notifyError({text: error});
  }
}

function handleTab(tab) {
  this.TabIndex = tab;
}

function getData(ContainerData, List) {
  this.MountedEdit = true;
  let WeigthData = ContainerData?.WeigthJson ? 
    ContainerData.WeigthJson.find(item => item.UnitMeasureId == ContainerData.UnitMeasureWeigth) 
    : {};
  let VgmData = ContainerData?.WeigthJson ? 
    ContainerData.WeigthJson.find(item => item.UnitMeasureId == ContainerData.UnitMeasureVgm) 
    : {};
  let VolumenData = ContainerData?.VolumenJson ? 
    ContainerData.VolumenJson.find(item => item.UnitMeasureId == ContainerData.UnitMeasureVolumen) 
    : {};
  this.Title = ContainerData.ContainerCode ?? '',
  this.GeneralInfo = {
    ContainerCode: ContainerData.ContainerCode ?? '',
    TpCargoStatusId: ContainerData.TpCargoStatusId ?? '',
    TpCargoConditionId: ContainerData.TpCargoConditionId ?? '',
    TpCargoClassId: ContainerData.TpCargoClassId ?? '',
    ShippingLineId: ContainerData.ShippingLineId ?? '',
    PortActivityId: ContainerData.PortActivityId ?? '',
    ShipperOwn: ContainerData.ShipperOwn ?? '',
    FgShipperOwn: ContainerData.ShipperOwn ? true : false,
    FgDirectDischarge: ContainerData.FgDirect ? true : false,
    LoadPortId: ContainerData.LoadPortId ?? '',
    DischargePortId: ContainerData.DischargePortId ?? '',
    DeliveryPortId: ContainerData.DeliveryPortId ?? '',
    CargoOriginPortId: ContainerData.OriginalPortLoadingId ?? '',
    YardId: ContainerData.YardId ?? '',
    Procedence: ContainerData.Procedence ?? '',
    BookingNro: ContainerData.BookingNumber ?? '',
    Status: 1,
  };
  this.StatusList = List.TpCargoStatusJson ?? [];
  this.ClassList = List.TpCargoClassJson ?? [];
  this.ShippingLineList = List.ServiceLineMasterJson ?? [];
  this.PortActivityList = List.TpPortActivityJson ?? [];
  this.ServicePortMasterList = List.ServicePortMasterJson ?? [];
  this.PortList = List.PortJson ?? [];
  this.Commodity = {
    BlNro: ContainerData.BlNo ?? '',
    Dua: ContainerData.Dua ?? '',
    ConsigneeId: ContainerData.ConsigneeId ?? '',
    DeclaredConsignee: ContainerData.Consignee ?? '',
    HeadingId: ContainerData.HeadingId ?? '',
    CommodityId: ContainerData.CommodityId ?? '',
  };
  this.ConsigneeList = List.ConsigneeJson ?? [];
  this.HeadingList = List.HeadingJson ?? [];
  this.IsoSeals = {
    FgApplyRefrigeration: ContainerData.FgApplyRefrigeration ?? false,
    FgApplyOversize: ContainerData.FgApplyOversize ?? false,
    MetadataId: ContainerData.MetadataId ?? '',
    Length: 0,
    UnitMeasureLength: ContainerData?.UnitMeasureLenght ?? '',
    Height: 0,
    UnitMeasureHeight: ContainerData?.UnitMeasureHeigth ?? '',
    Weight: WeigthData?.Value ?? 0,
    UnitMeasureWeight: ContainerData.UnitMeasureWeigth ?? '',
    PreviousWeightUnitMeasure: ContainerData.UnitMeasureWeigth ?? '',
    Vgm: VgmData?.Value ?? 0,
    UnitMeasureVgm: ContainerData.UnitMeasureVgm ?? '',
    PreviousVgmUnitMeasure: ContainerData.UnitMeasureVgm ?? '',
    Volumen: VolumenData?.Value ?? 0,
    UnitMeasureVolumen: ContainerData.UnitMeasureVolumen ?? '',
    PreviouVolumenUnitMeasure: ContainerData.UnitMeasureVolumen ?? '',
    Seal1: ContainerData.Seal1 ?? '',
    Seal2: ContainerData.Seal2 ?? '',
    Seal3: ContainerData.Seal3 ?? '',
    Seal4: ContainerData.Seal4 ?? '',
  };
  this.UnitMeasureLongitudeList = List.UnitMeasureLongitudeJson ?? [];
  this.UnitMeasureWeigthList = List.UnitMeasureWeigthJson ?? [];
  this.UnitMeasureVolumenList = List.UnitMeasureVolumenJson ?? [];
  this.DangerousGood = {
    ImdgId: ContainerData.ImdgId ?? '',
    ImdgClassId: ContainerData.ImdgClassId ?? '',
    UnNumber: ContainerData.UnNumber ?? '',
    PackagingGroupId: ContainerData.PackagingGroupId ?? '',
    DangerousGood: ContainerData.DescriptionOfGoodsDGS ?? '',
  };
  this.ImdgClassCodeList = List.ImdgClassJson ?? [];
  this.PackagingGroupList = List.PackagingGroupJson ?? [];
  this.DescriptionOfGoods = {
    DescriptionOfGoods: ContainerData.DescriptionOfGoods ?? '',
  };
  this.ReeferEquipment = {
    ReeferSetting: ContainerData.ReeferSetting ?? '',
    UnitMeasureRf: ContainerData.UnitMeasureRfId ?? '',
    PreviousRfUnitMeasure: ContainerData.UnitMeasureRfId ?? '',
    MinimumRangeRf: ContainerData.MinimumRangeRf ?? 0,
    MaximumRangeRf: ContainerData.MaximumRangeRf ?? 0,
  };
  this.UnitMeasureTemperatureList = List.UnitMeasureTemperatureJson ?? [];
  this.OversizedEquipment = {
    UnitMeasureDim: ContainerData.UnitMeasureDimId ?? '',
    PreviousDimUnitMeasure: ContainerData.UnitMeasureDimId ?? '',
    DimFront: ContainerData.DimFront ?? 0,
    DimBack: ContainerData.DimBack ?? 0,
    DimLeft: ContainerData.DimLeft ?? 0,
    DimRight: ContainerData.DimRight ?? 0,
    DimTop: ContainerData.DimTop ?? 0,
  };
  this.UnitMeasureDimList = List.UnitMeasureDimJson ?? [];
  this.$v.$touch();
}

async function UpdateBlList() {
  await this.$emit('submited');
}

async function Reset() {
  this.Title = '';
  this.TabIndex = 0;
  this.isSubmit = false;
  this.MountedEdit = false;
  this.GeneralInfo = {
    ContainerCode: '',
    TpCargoStatusId: '',
    TpCargoConditionId: '',
    TpCargoClassId: '',
    ShippingLineId: '',
    PortActivityId: '',
    ShipperOwn: '',
    FgShipperOwn: false,
    FgDirectDischarge: false,
    LoadPortId: '',
    DischargePortId: '',
    DeliveryPortId: '',
    CargoOriginPortId: '',
    YardId: '',
    Procedence: '',
    BookingNro: '',
    Status: 1,
  };
  this.StatusList = [],
  this.ClassList = [],
  this.ShippingLineList = [];
  this.PortActivityList = [];
  this.ServicePortMasterList = [];
  this.PortList = [];
  this.YardList = [];
  this.Commodity = {
    BlNro: '',
    Dua: '',
    ConsigneeId: '',
    HeadingId: '',
    CommodityId: '',
  };
  this.ConsigneeList = [];
  this.HeadingList = [];
  this.IsoSeals = {
    FgApplyRefrigeration: false,
    FgApplyOversize: false,
    MetadataId: '',
    Length: 0,
    UnitMeasureLength: '',
    Height: 0,
    UnitMeasureHeight: '',
    Weight: 0,
    UnitMeasureWeight: '',
    PreviousWeightUnitMeasure: '',
    Vgm: 0,
    UnitMeasureVgm: '',
    PreviousVgmUnitMeasure: '',
    Volumen: 0,
    UnitMeasureVolumen: '',
    PreviouVolumenUnitMeasure: '',
    Seal1: '',
    Seal2: '',
    Seal3: '',
    Seal4: '',
  };
  this.IsoList = [];
  this.UnitMeasureLongitudeList = [];
  this.UnitMeasureWeigthList = [];
  this.UnitMeasureVolumenList = [];
  this.DangerousGood = {
    ImdgId: '',
    ImdgClassId: '',
    UnNumber: '',
    PackagingGroupId: '',
    DangerousGood: '',
  };
  this.ImdgClassCodeList = [];
  this.PackagingGroupList = [];
  this.DescriptionOfGoods = {
    DescriptionOfGoods: '',
  };
  this.ReeferEquipment = {
    ReeferSetting: '',
    UnitMeasureRf: '',
    PreviousRfUnitMeasure: '',
    MinimumRangeRf: 0,
    MaximumRangeRf: 0,
  };
  this.UnitMeasureTemperatureList = [];
  this.OversizedEquipment = {
    UnitMeasureDim: '',
    PreviousDimUnitMeasure: '',
    DimFront: 0,
    DimBack: 0,
    DimLeft: 0,
    DimRight: 0,
    DimTop: 0,
  };
  this.UnitMeasureDimList = [];
  this.$v.$reset();
}

async function Close() {
  this.$emit('Close');
}

//computed
function title() {
	return `${this.$t('label.edit')} ${this.$t('label.container')}: ${this.Title}`
}

function IsoData() {
  let Data = this.IsoList.find(item => item.MetadataId == this.IsoSeals.MetadataId) ?? {};
  if (!this.MountedEdit) {
    this.IsoSeals.FgApplyRefrigeration = Data?.FgApplyRefrigeration ? true : false;
    this.IsoSeals.FgApplyOversize = Data?.FgApplyOversize ? true : false;
    this.IsoSeals.UnitMeasureLength = Data?.LenghtJson&&Data?.LenghtJson[0] ? Data?.LenghtJson[0].UnitMeasureId : this.IsoSeals.UnitMeasureLength;
    this.IsoSeals.UnitMeasureHeight = Data?.HeigthJson&&Data?.HeigthJson[0] ? Data?.HeigthJson[0].UnitMeasureId : this.IsoSeals.UnitMeasureHeight;
  }else {
    if(!this.IsoSeals.UnitMeasureLength || !this.IsoSeals.UnitMeasureHeight){
      this.IsoSeals.UnitMeasureLength = Data?.LenghtJson&&Data?.LenghtJson[0] ? Data?.LenghtJson[0].UnitMeasureId : this.IsoSeals.UnitMeasureLength;
      this.IsoSeals.UnitMeasureHeight = Data?.HeigthJson&&Data?.HeigthJson[0] ? Data?.HeigthJson[0].UnitMeasureId : this.IsoSeals.UnitMeasureHeight;
    }
    this.MountedEdit = false;
  }
  return Data;
}

function LimitWeight(){
  let value = 0;
  let UnitMeasure = {};
  if (typeof this.GeneralInfo.TpCargoStatusId == 'string') {
    switch (this.GeneralInfo?.TpCargoStatusId) {
      case process.env.VUE_APP_TP_CARGO_STATUS_EMPTY:
        UnitMeasure = this.IsoData?.WeigthEmptyJson?.find((e) => this.IsoSeals.UnitMeasureWeight === e.UnitMeasureId);
        if(UnitMeasure){
          value = Number.parseFloat(UnitMeasure.Value);
        }
        break;
          
      case process.env.VUE_APP_TP_CARGO_STATUS_FULL:
        UnitMeasure = this.IsoData?.MaxGrossWeigthJson?.find((e) => this.IsoSeals.UnitMeasureWeight === e.UnitMeasureId );
        if(UnitMeasure){
          value = Number.parseFloat(UnitMeasure.Value);
        }
        break;
    }
  }
  return value;
}

function LimitVgm(){
    let value = 0;
    if(this.GeneralInfo?.TpCargoStatusId == process.env.VUE_APP_TP_CARGO_STATUS_FULL){
      let UnitMeasure = this.IsoData?.MaxLoadJson?.find((e) => this.IsoSeals.UnitMeasureVgm === e.UnitMeasureId );
      if(UnitMeasure){
        value = Number.parseFloat(UnitMeasure.Value);
      }
    }
    return value;
}

function ValidateStatusEmpty() {
  if (typeof this.GeneralInfo.TpCargoStatusId == 'string') {
    return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.GeneralInfo?.TpCargoStatusId;
  }else{
    return false;
  }
}

function VerifySeal() {
  if (this.ValidateStatusEmpty) {
    return false;
  } else if (!this.IsoSeals.Seal1 && !this.IsoSeals.Seal2 && !this.IsoSeals.Seal3 && !this.IsoSeals.Seal4) {
      return true;
  } else {
    return false;
  }
}

function Validate() {
  return {
    FgShipperOwn: this.GeneralInfo.FgShipperOwn,
    PortActivicty: this.GeneralInfo.PortActivityId,
    FgCompanyBranchPort: this.GeneralInfo.LoadPortId == this.branch?.CompanyBranchId || 
      this.GeneralInfo?.DischargePortId == this.branch?.CompanyBranchId,
    FgDirectDischarge: this.GeneralInfo.FgDirectDischarge,
    ValidateStatusEmpty: this.ValidateStatusEmpty,
    FgConsigneeBl: this.BlClientTpId != this.Commodity.ConsigneeId,
    LimitWeight: this.LimitWeight,
    LimitVgm: this.LimitVgm,
    FgVgm: NumberFormater.setNum(this.IsoSeals.Vgm) > 0,
    FgVolumen: NumberFormater.setNum(this.IsoSeals.Volumen) > 0,
    VerifySeal: this.VerifySeal,
    FgApplyRefrigeration: this.IsoSeals.FgApplyRefrigeration,
    FgRf: NumberFormater.setNum(this.ReeferEquipment.MinimumRangeRf) > 0 || NumberFormater.setNum(this.ReeferEquipment.MaximumRangeRf) > 0,
    Minimum: NumberFormater.setNum(this.ReeferEquipment.MinimumRangeRf) ?? 0,
    Maximum: NumberFormater.setNum(this.ReeferEquipment.MaximumRangeRf) ?? 0,
    FgApplyOversize: this.IsoSeals.FgApplyOversize,
  }
}

export default {
  name: 'general-cargo-modal-index',
  components: {
    CustomTabs,
    CustomTab,
    GeneralInfoTab,
    CommodityTab,
    IsoSealsTab,
    DangerousGoodTab,
    DescriptionOfGoodsTab,
    ReeferEquipmentTab,
    OversizedEquipmentTab,
  },
  data,
  props: {
    modal: Boolean, 
    VisitCargoId:  {
      type: String,
      default: () => '',
    },
    BlClientTpId:  {
      type: String,
      default: () => '',
    },
  },
  mixins: [ModalMixin],
  validations() {
    return BlContainerValidations(this.Validate);
  },
  methods: {
    getService,
    Submit,
    handleTab,
    getData,
    UpdateBlList,
    Reset,
    Close,
  },
	computed:{
		title,
    IsoData,
    LimitWeight,
    LimitVgm,
    ValidateStatusEmpty,
    VerifySeal,
    Validate,
    ...mapState({
      branch: state => state.auth.branch,
      StowagePlanningId: state => state.planificacionestiba.planificacionId,
    }),
	},
  watch: {
    modal: async function(Newval){
			this.showModal = Newval;
      this.show = Newval;
      if (Newval) {
        await this.getService();
      }else{
        this.Reset();
      }
    },
  },
};
</script>