const SoloEnteros = (e) => {
  var regex = new RegExp("^[0-9]+$");
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
      return true;
  }
  e.preventDefault();
  return false
};

const SoloNumeros = (e) => {
  var regex = new RegExp("^[0-9.]+$");
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
      return true;
  }
  e.preventDefault();
  return false
};

const SoloAlfanumericos = (e) => {
  var regex = new RegExp("^[-\/\\a-zA-ZñÑ.,;0-9@ ]+$");
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
      return true;
  }

  e.preventDefault();
  return false
};

const SoloLetras = (e) => {
  var regex = new RegExp("^[a-zA-Z ]+$");
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
      return true;
  }

  e.preventDefault();
  return false
}

const credentials = (value) => /(^[V,P,J,E]{1})(\d{8,9})$/gm.test(value);
const onlyText = (value) => /^[a-zA-Z \u00f1\u00d1]+$/g.test(value);
const cellphone = (value) => /(^[+]{1})([\d]{11,15})+$/g.test(value);
const phoneTest = (value) => /^[\d]{4}[-]?\d{7}$|^[\+\d]{2,4}[-]?\d{7,11}$/.test(value);
const alphaNumSpecials = (value) => /^[\w\u00f1\u00d1,.*\+\-\#\/\$\!\(\)]+$/g.test(value);
const alphaSpecials = (value) => /^[a-zA-Z \u00f1\u00d1,.*\+\-\#\/\$\!\(\)]+$/g.test(value);
const textareaSpecials = (value) => /^[\w \u00f1\u00d1,.*\+\-\#\/\$\!\(\)]+$/gm.test(value);
const onlyNumbers = (value) => /^[0-9.]+$/.test(value);
const metrics = (value) => /^(?:[1-9][0-9]{0,15}|0)(\.[1-9])?(\.\d{2})?$/.test(value);
const alphaNumSpecials2 = (value) => /^[\w\u00f1\u00d1\u0020\.\,\-\&\/\\\)]+$/g.test(value);

const isLatitude = (lat) => {
  return lat.trim() !== '' && isFinite(lat) && Math.abs(lat) <= 90;
}
const isLongitude = (lng) => {
  return lng.trim() !== '' && isFinite(lng) && Math.abs(lng) <= 180;
}

const birthDateValidation = (date) => {
  let year = new Date().getFullYear() - 18;
  let minDate = new Date('01/01/1900'),
      maxDate = new Date(`01/01/${year}`);

  return (date >= maxDate || date <= minDate);
}
const dateValidationByYearQty = (date, qty = 1) => {
  let today = new Date(),
      day = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate(),
      month = today.getMonth() + 1,
      year = today.getFullYear() - 1;

      month = month < 10 ? `0${month}` : month;
  let minDate = new Date(`${month}/${day}/${year}`),
      maxDate = new Date();

  return (date >= maxDate || date <= minDate);
}

export { 
  SoloEnteros,
  SoloNumeros,
  SoloAlfanumericos,
  SoloLetras,
  credentials,
  onlyText,
  cellphone,
  phoneTest,
  alphaNumSpecials,
  alphaNumSpecials2,
  alphaSpecials,
  
  textareaSpecials,
  onlyNumbers,
  metrics,
  isLatitude,
  isLongitude,
  birthDateValidation,
  dateValidationByYearQty,
};
