import { required, requiredIf, maxLength, minLength, minValue, maxValue, sameAs, not, helpers } from "vuelidate/lib/validators";
import { SoloContenedores, onlyAlphanumeric, onlyAlphanumeric3, SoloDecimales } from '@/_validations/validacionEspeciales'
import { NumberFormater } from "@/_helpers/funciones";

const charValue = (char, special = false) =>{
  let values = {
    A:10,
    B:12,
    C:special?2:13,
    D:14,
    E:15,
    F:16,
    G:17,
    H:special?4:18,
    I:19,
    J:20,
    K:21,
    L:special?0:23,
    M:24,
    N:25,
    O:26,
    P:27,
    Q:28,
    R:29,
    S:30,
    T:31,
    U:special?9:32,
    V:34,
    W:35,
    X:36,
    Y:37,
    Z:38,
  }
  return values[char];
}

async function containerCodeValidation(text) {
  try {
    let chars = text.split('');
    let special = text.substr(0,3) == "HLCU";
    let charValues = await chars.map((element, index)=>{
      let value;
      if(index< 4){
          value = charValue(element, special);
      } else {
          value = parseInt(element);
      } 

      if( index == (chars.length-1))
          return value;
      
      value = parseInt(value * Math.pow(2, index));

      return value;
    });

    let plusValues = 0;

    await charValues.forEach((element, index) => {
        if (index<(charValues.length-1))
            plusValues = parseInt(plusValues+element);
    });
    
    let resultDevided = Math.floor(plusValues/11) ;
    let resultTimes = parseInt(resultDevided*11)
    let lastChar = parseInt(plusValues - resultTimes);

    if(lastChar == 10){
        lastChar = 0;
    }

    if (charValues[charValues.length-1] != lastChar){
        throw (`checkDigitInvalid`);
    } 
        
    return true;
  } catch (e){
    return false;
  }
}

export default (Validate) => {
  let PortRequire = Validate?.PortActivicty ? required : true;
  let ValidateReefer = Validate?.FgApplyRefrigeration ? { required } : {};
  let ValidateCommodity = !Validate?.ValidateStatusEmpty ? { required } : {};
  let ValidateDirectDischarge = !Validate?.FgDirectDischarge ? { required } : {};
  return {
    GeneralInfo:{
      ContainerCode: {
        required,
        onlyAlphanumeric,
        SoloContenedores,
        checkDigit: containerCodeValidation,
        minLength: minLength(1),
        maxLength: maxLength(11)
      },
      TpCargoStatusId: { required },
      TpCargoConditionId: { required },
      TpCargoClassId: {},
      ShippingLineId: { required },
      PortActivityId: { required },
      ShipperOwn: Validate?.FgShipperOwn ? { required } : {},
      LoadPortId: {
        required: PortRequire,
        NotSameAsPLAndPOD: not(sameAs('DischargePortId')),
        CompanyBranchPort: Validate?.FgCompanyBranchPort ? true : false,
      },
      DischargePortId: {
        required: PortRequire,
        NotSameAsPLAndPOD: not(sameAs('LoadPortId')),
        CompanyBranchPort: Validate?.FgCompanyBranchPort ? true : false,
      },
      DeliveryPortId: {
        NotSameLoadPortAndDeliveryPort: not(sameAs('CargoOriginPortId'))
      },
      CargoOriginPortId: {
        NotSameLoadPortAndDeliveryPort: not(sameAs('DeliveryPortId'))
      },
      YardId: ValidateDirectDischarge,
      Procedence: { required },
      BookingNro: {},
    },
    Commodity:{
      Dua: {},
      ConsigneeId: { required, ConsigneeBl(){ return !Validate?.FgConsigneeBl } },
      DeclaredConsignee: {},
      HeadingId: ValidateCommodity,
      CommodityId: ValidateCommodity,
    },
    IsoSeals: {
      MetadataId: { required },
      Length: { 
        required(Value){
          let ValueNumber = NumberFormater.setNum(Value);
          return ValueNumber > 0;
        },
        maxValue(Value){
          let ValueNumber = NumberFormater.setNum(Value);
          return ValueNumber < 100000.00;
        }
      },
      UnitMeasureLength: { required },
      Height: { 
        required(Value){
          let ValueNumber = NumberFormater.setNum(Value);
          return ValueNumber > 0;
        },
        maxValue(Value){
          let ValueNumber = NumberFormater.setNum(Value);
          return ValueNumber < 100000.00;
        }
      },
      UnitMeasureHeight: { required },
      Weight: {
        required(Value){
          let ValueNumber = NumberFormater.setNum(Value);
          return ValueNumber > 0;
        },
        limit(value) {
          let ValueNumber = NumberFormater.setNum(value);
          return ValueNumber <= Validate?.LimitWeight ?? 0;
        }
      },
      UnitMeasureWeight: { required },
      Vgm: {
        limit(value) {
          let ValueNumber = NumberFormater.setNum(value);
          return ValueNumber <= Validate?.LimitVgm ?? 0;
        }
      },
      UnitMeasureVgm: { 
        required(value){
          if (Validate?.FgVgm) {
            return value ? true : false;
          }else{
            return true;
          }
        }
      },
      Volumen: {},
      UnitMeasureVolumen: { 
        required(value){
          if (Validate?.FgVolumen) {
            return value ? true : false;
          }else{
            return true;
          }
        }
      },
      Seal1: { 
        required(){ return !Validate?.VerifySeal },
        maxLength: maxLength(20)
      },
      Seal2: { 
        required(){ return !Validate?.VerifySeal },
        maxLength: maxLength(20)
      },
      Seal3: { 
        required(){ return !Validate?.VerifySeal },
        maxLength: maxLength(20)
      },
      Seal4: { 
        required(){ return !Validate?.VerifySeal },
        maxLength: maxLength(20)
      },
    },
    DangerousGood: {
      ImdgId: {},
      ImdgClassId: {},
      UnNumber: {},
      PackagingGroupId: {},
      DangerousGood: {},
    },
    DescriptionOfGoods: {
      DescriptionOfGoods: {},
    },
    ReeferEquipment: {
      ReeferSetting: ValidateReefer,
      UnitMeasureRf: ValidateReefer,
      MinimumRangeRf: { 
        required(value){ 
          if (Validate?.FgApplyRefrigeration) {
            return NumberFormater.setNum(value) > 0
          }else {
            return true;
          }
        },  
        maxValue: maxValue(Validate?.Maximum ?? 0) 
      },
      MaximumRangeRf: { 
        required(value){ 
          if (Validate?.FgApplyRefrigeration) {
            return NumberFormater.setNum(value) > 0
          }else {
            return true;
          }
        }, 
        minValue: minValue(Validate?.Minimum ?? 0) 
      },
    },
    OversizedEquipment: {
      UnitMeasureDim: { 
        required(value){ 
          if (Validate?.FgApplyOversize) {
            return value ? true : false;
          }else{
            return true;
          }
        }, 
      },
      DimFront: { 
        required(value){ 
          if (Validate?.FgApplyOversize) {
            return NumberFormater.setNum(value) > 0 
          } else {
            return true;
          }
        } 
      },
      DimBack: { 
        required(value){ 
          if (Validate?.FgApplyOversize) {
            return NumberFormater.setNum(value) > 0 
          } else {
            return true;
          }
        } 
      },
      DimLeft: { 
        required(value){ 
          if (Validate?.FgApplyOversize) {
            return NumberFormater.setNum(value) > 0 
          } else {
            return true;
          }
        } 
      },
      DimRight: { 
        required(value){ 
          if (Validate?.FgApplyOversize) {
            return NumberFormater.setNum(value) > 0 
          } else {
            return true;
          }
        } 
      },
      DimTop: { 
        required(value){ 
          if (Validate?.FgApplyOversize) {
            return NumberFormater.setNum(value) > 0 
          } else {
            return true;
          }
        } 
      },
    },
    validationGroup: [
      'GeneralInfo.TpCargoStatusId',
      'GeneralInfo.TpCargoConditionId',
      'GeneralInfo.TpCargoClassId',
      'GeneralInfo.ShippingLineId',
      'GeneralInfo.PortActivityId',
      'GeneralInfo.ShipperOwn',
      'GeneralInfo.LoadPortId',
      'GeneralInfo.DischargePortId',
      'GeneralInfo.DeliveryPortId',
      'GeneralInfo.CargoOriginPortId',
      'GeneralInfo.Procedence',
      'GeneralInfo.BookingNro',
      'Commodity.Dua',
      'Commodity.DeclaredConsignee',
      'Commodity.HeadingId',
      'Commodity.ValidateCommodity',
      'IsoSeals',
      'DangerousGood',
      'DescriptionOfGoods',
      'ReeferEquipment',
      'OversizedEquipment',
    ]
  }
  
}